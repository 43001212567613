import React from "react";
import { Col, Row } from "react-bootstrap";

import {
  DiJavascript1,
  DiHtml5,
  DiCss3,
  DiReact,

  DiMongodb,

  DiBootstrap
} from "react-icons/di";
import { SiRedux,SiMaterialUi} from "react-icons/si"

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <DiHtml5 />
        <h4>HTML</h4>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiCss3 />
        <h4>CSS</h4>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiJavascript1 />
        <h4>Javascript</h4>
      </Col>
      
      <Col xs={4} md={2} className="tech-icons">
        <DiReact />
        <h4>React</h4>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiRedux />
        <h4>Redux</h4>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiMongodb />
        <h4>MongoDb</h4>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <img width="100" src="https://www.pngfind.com/pngs/m/136-1363736_express-js-icon-png-transparent-png.png" alt="img"/>
        <h4>Express</h4>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <img width="100" src="https://miro.medium.com/max/1200/1*4E0JF4BkB4lkJrf8G30qlg.png" alt="img"/>
        <h4>Mongoose</h4>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiBootstrap />
        <h4>Bootstrap</h4>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiMaterialUi />
        <h4>MaterialUi</h4>
      </Col>
    </Row>
  );
}

export default Techstack;
