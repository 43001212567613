import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiVisualstudiocode,
  SiPostman,
  SiGithub,
  SiGit,
} from "react-icons/si";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
     
      <Col xs={4} md={2} className="tech-icons">
        <SiVisualstudiocode />
        <h4>Visual studio</h4>
      </Col>
  
      <Col xs={4} md={2} className="tech-icons">
        <SiPostman />
        <h4>Postman</h4>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiGithub />
        <h4>Github</h4>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiGit/>
        <h4>Git</h4>
      </Col>
    </Row>
  );
}

export default Toolstack;
